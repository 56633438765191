var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center", align: "center" } },
    [
      _c(
        "v-col",
        {
          staticClass: "py-0",
          attrs: { cols: "10", sm: "10", md: "6", lg: "6" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "card text-center shadow-sm p-3 mb-5 bg-white rounded",
            },
            [
              _c("div", { staticClass: "card-header border-0 py-5" }, [
                _c("span", [
                  _c("img", {
                    attrs: { src: "media/undraw/mail-sent.png", height: "200" },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "card-body" }, [
                _c("h5", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.status)),
                ]),
                _c(
                  "p",
                  { staticClass: "card-text" },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c("ConfirmationDialog", {
            attrs: {
              title: this.dialogTitle,
              content: this.dialogMsg,
              showProceedButton: true,
              showBackButton: false,
              delayOnProceed: false,
              proceedButton: "Ok",
            },
            on: { onProceed: _vm.onProceed },
            model: {
              value: _vm.openConfirmationDialog,
              callback: function ($$v) {
                _vm.openConfirmationDialog = $$v
              },
              expression: "openConfirmationDialog",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }