import { render, staticRenderFns } from "./VerifyEmail.vue?vue&type=template&id=7e187fe7&scoped=true&"
import script from "./VerifyEmail.vue?vue&type=script&lang=js&"
export * from "./VerifyEmail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e187fe7",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VProgressCircular,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/fared/kowamas-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7e187fe7')) {
      api.createRecord('7e187fe7', component.options)
    } else {
      api.reload('7e187fe7', component.options)
    }
    module.hot.accept("./VerifyEmail.vue?vue&type=template&id=7e187fe7&scoped=true&", function () {
      api.rerender('7e187fe7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/pages/VerifyEmail.vue"
export default component.exports