<template>
  <v-row justify="center" align="center">
    <v-col class="py-0" cols="10" sm="10" md="6" lg="6">
      <div class="card text-center shadow-sm p-3 mb-5 bg-white rounded">
        <div class="card-header border-0 py-5">
          <span>
            <img src="media/undraw/mail-sent.png" height="200" />
          </span>
        </div>

        <div class="card-body">
          <h5 class="card-title">{{ status }}</h5>
          <!-- <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> -->
          <p class="card-text">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </p>
        </div>
      </div>
      <ConfirmationDialog
        v-model="openConfirmationDialog"
        :title="this.dialogTitle"
        :content="this.dialogMsg"
        :showProceedButton="true"
        :showBackButton="false"
        :delayOnProceed="false"
        proceedButton="Ok"
        @onProceed="onProceed"
      ></ConfirmationDialog>
    </v-col>
  </v-row>
</template>
<script>
import {
  /* VERIFY_EMAIL, */
  REDIRECTOR,
// } from "@/core/services/store/notification.module";
} from "@/core/services/store/redirector.module";
// import ConfirmationDialog from "@/view/pages/widgets/ConfirmationDialog.vue";
export default {
  name: "VerifyEmail",
  components: {
    // ConfirmationDialog,
  },
  data() {
    return {
      openConfirmationDialog: false,
      dialogMsg:
        "Pautan pengesahan e-mel ini telah luput dan tidak lagi sah untuk digunakan kerana pengesahan e-mel sebelum ini telah berjaya dilakukan.",
      dialogTitle: "Berjaya",
      status: "Mengesahkan E-mel anda, sila tunggu..",
      next: "/dashboard",
    };
  },
  computed: {},
  async mounted() {
    //nfh-console.log(" IN:mounted");
    // debugger;
    await this.$store
      // .dispatch(VERIFY_EMAIL, {
      //   token: this.$route.query.t,
      //   from: this.$route.query.fr,
      //   next: this.$route.query.nx,
      // })
      .dispatch(REDIRECTOR, this.$route.query)
      .then((response) => {
        // debugger;
        if (response?.data?.success == true) {
          this.next = response.data.next;
          this.dialogTitle = "Tahniah";
          this.dialogMsg = response.data.message;
          this.status = "E-mel telah berjaya disahkan.";
          this.openConfirmationDialog = true;
        }
        else {
          this.dialogTitle = "Harap Maaf";
          this.dialogMsg = response.data.message;
          this.openConfirmationDialog = true;
        }
      })
      .catch((err) => {
        this.dialogTitle = "Pengesahan Tidak Dapat Diteruskan";
        this.dialogMsg = err.toString();
        //nfh-console.log(err);
        if (err?.message) {
          if (err.message == "Invalid signature") {
            this.dialogMsg = "Pautan pengesahan e-mel tidak sah dan telah diubah. Pengesahan ini akan dilaporkan untuk siasatan.";
          }
        }
        this.openConfirmationDialog = true;
      })
      .finally(() => {});
    //nfh-console.log("OUT:mounted");
  },
  methods: {
    onProceed() {
      this.openConfirmationDialog = false;
      this.$router.push({
        path: this.next,
      });
    },
  },
};
</script>
<style scoped>
</style>